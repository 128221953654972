<template>
  <div class="details">
    <ShopNavBar title="申请详情" />

    <div class="container">
      <div class="cell">
        <div>申请时间</div>
        <div class="cell-value">{{ apply.createTime }}</div>
      </div>
      <div class="cell">
        <div>申请级别</div>
        <div class="cell-value">{{ apply.shopLevelName }}</div>
      </div>
      <div class="cell">
        <div>店铺名称</div>
        <div class="cell-value">{{ apply.shopName }}</div>
      </div>
      <div class="cell">
        <div>地区</div>
        <div class="cell-value">{{ apply.shopSheng + apply.shopShi + apply.shopXian }}</div>
      </div>
      <div class="cell">
        <div>详细地址</div>
        <div class="cell-value">{{ apply.shopAddress }}</div>
      </div>
      <div class="cell">
        <div>联系电话</div>
        <div class="cell-value">{{ apply.shopFrdh }}</div>
      </div>
      <div class="cell">
        <div>备注</div>
        <div class="cell-value">{{ apply.bz }}</div>
      </div>
      <div class="cell">
        <div>状态</div>
        <div class="cell-value">
          <van-tag color="#ff2d2e" v-if="apply.state == 0">{{ apply.stateName }}</van-tag>
          <van-tag type="success" v-if="apply.state == 1">{{ apply.stateName }}</van-tag>
          <van-tag color="gray" v-if="apply.state == 2">{{ apply.stateName }}</van-tag>
        </div>
      </div>
      <div class="cell" v-if="apply.state == 2 && apply.rejectMsg != ''">
        <div>驳回原因</div>
        <div class="cell-value">{{ apply.rejectMsg }}</div>
      </div>
      <div class="cell">
        <div>门店照片</div>
        <div class="cell-value">
          <img :src="getimg(apply.shopImg)" width="100" />
        </div>
      </div>
      <div class="cell">
        <div>营业执照</div>
        <div class="cell-value">
          <img :src="getimg(apply.shopYyzz)" width="100" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tag } from 'vant';
import { ref } from 'vue';

export default {
  components: {
    [Tag.name]: Tag
  },
  setup() {
    return {
      us: ref(null),
      id: ref(""),
      apply: ref({})
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.id = this.$route.query.id
    this.getdata()
  },
  methods: {
    getimg(img) {
			if (img != "") {
				return this.$config.send_url + "Upload/" + img;
			}
		},
    getdata() {
      let _this = this
      _this.$request.post(
        "api/UsersShopApply/Get",
        {
          userid: _this.us.userid,
          id: _this.id
        },
        (res) => {
          if(res.data.code == 100) {
            let data = res.data.data
            _this.apply = data
            // console.log(data)
          }
        }
      )
    }
  }
}
</script>

<style scoped>
.details {
  padding: 10px;
  min-height: 100vh;
  background: rgba(242, 242, 242, 1);
}

.container {
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
}

.cell {
  padding: 10px 0;
  display: flex;
}

.cell-value {
  margin-left: 60px;
  flex: 1;
  text-align: right;
}
</style>